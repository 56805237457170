@use '../../global.style.module' as g;
// COMPONENTS
// masthead
.mastheadContainer {
  @include g.flex-column-start;
  width: 100%;
  gap: 32px;
  @include g.responsiveSectionSpacing;
  @include g.responsive(g.$MEDIUM) {
    gap: 80px;
  }

  @include g.responsive(g.$LARGE) {
    gap: 0;
  }
}
.mastheadBanner {
  @include g.flex-column-start;
  width: 100%;
  flex-direction: column-reverse;
  gap: 32px;
  height: 100%;

  @include g.responsive(g.$MEDIUM) {
    gap: 80px;
  }

  @include g.responsive(g.$LARGE) {
    @include g.flex-row-center;
    gap: 32px;
  }
}

.mastheadH1 {
  @include g.h5;
  color: var(--purple-primary) !important;
  white-space: nowrap;

  &.dark {
    color: var(--white-primary) !important;
  }

  @include g.responsive(g.$MEDIUM) {
    @include g.h4;
  }
}
.mastheadH2 {
  @include g.h3;
  font-weight: var(--anton-font-weight) !important;
  color: var(--neutral-800);
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  &.dark {
    color: var(--purple-light) !important;
  }

  @include g.responsive(g.$MEDIUM) {
    @include g.h1;
    line-clamp: 2;
    -webkit-line-clamp: 2;
  }

  @include g.responsive(g.$LARGE) {
    line-clamp: 3;
    -webkit-line-clamp: 3;
  }
}

.descriptionContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mastheadDescription {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  text-align: left;
  color: var(--neutral-800);
  @include g.bodyRegular;

  &.dark {
    color: var(--white-primary) !important;
  }

  @include g.responsive(g.$MEDIUM) {
    @include g.bodyLarge;
  }

  @include g.responsive(g.$LARGE) {
    text-align: center;
    max-width: 937px;
    justify-content: center;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}

.textContainer {
  width: 100%;

  @include g.responsive(g.$LARGE) {
    width: var(--text-width);
    padding: var(--spacing-section-large) 0;
  }

  @include g.responsive(g.$X_LARGE) {
    width: var(--text-width-xl);
    padding: var(--spacing-section-xl) 0;
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include g.responsive(g.$LARGE) {
    width: var(--image-width);
  }
  @include g.responsive(g.$X_LARGE) {
    width: var(--image-width-xl);
  }
}

.imageInnerContainer {
  display: flex;
  width: var(--image-width-s);

  @include g.responsive(g.$MEDIUM) {
    width: var(--image-width-m);
  }

  @include g.responsive(g.$LARGE) {
    width: var(--image-width-l);
  }
}

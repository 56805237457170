@use '../../global.style.module' as g;
// COMPONENTS
// masthead
.mastheadContainer {
  @include g.flex-column-start;
  width: 100%;
  gap: 32px;
  margin-bottom: 80px;

  @include g.responsive(g.$MEDIUM) {
    gap: 80px;
  }

  @include g.responsive(g.$LARGE) {
    gap: 0;
    margin-bottom: 0;
  }
}
.mastheadBanner {
  @include g.flex-column-start;
  width: 100%;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  @include g.responsive(g.$LARGE) {
    @include g.flex-row-center;
  }
}

.mastheadH1 {
  color: var(--Text-Masthead-Subtitle, #f2e5ff);
  margin-bottom: var(--spacing-section-small);
  letter-spacing: 0.5px;
  @include g.pageTitleSmall;

  @include g.responsive(g.$MEDIUM) {
    @include g.h1;
  }
}
.mastheadH2 {
  @include g.bodyRegular;
  color: var(--white-primary);

  @include g.responsive(g.$MEDIUM) {
    @include g.bodyLarge;
  }
}

.mastheadImage {
  width: 265px;
  height: 100%;

  @include g.responsive(g.$MEDIUM) {
    display: flex;
    width: 381px;
  }

  @include g.responsive(g.$LARGE) {
    display: flex;
    width: 306px;
  }

  @include g.responsive(g.$X_LARGE) {
    display: flex;
    width: 379px;
  }
}

.descriptionContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.textContainer {
  width: 100%;

  @include g.responsive(g.$LARGE) {
    width: var(--text-width);
    padding: var(--spacing-section-large) 0;
  }

  @include g.responsive(g.$X_LARGE) {
    padding: var(--spacing-section-xl) 0;
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 265px;

  @include g.responsive(g.$MEDIUM) {
    display: flex;
    width: 381px;
  }

  @include g.responsive(g.$LARGE) {
    display: flex;
    width: 306px;
  }

  @include g.responsive(g.$X_LARGE) {
    display: flex;
    width: 379px;
  }
}

.imageInnerContainer {
  display: flex;
  width: var(--image-width-s);

  @include g.responsive(g.$MEDIUM) {
    width: var(--image-width-m);
  }

  @include g.responsive(g.$LARGE) {
    width: var(--image-width-l);
  }
}

@use '../../global.style.module' as g;

.container {
  display: flex;
  flex-direction: var(--flex-direction);
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  gap: var(--spacing-section-small);
  @include g.responsiveSectionSpacing;
  @include g.responsive(g.$LARGE) {
    flex-direction: row;
    gap: var(--spacing-32, 32px);
  }
}

.imageContainer {
  img {
    height: auto;
    width: var(--img-mobile-width);
    @include g.responsive(g.$LARGE) {
      width: 100%;
    }
  }
}

.richText {
  @include g.typographySpacingRegular;

  li {
    list-style-type: none;
    position: relative;
    padding-left: 26.5px;
  }

  li::before {
    content: '\2022';
    font-size: 18px;
    line-height: 20px;
    position: absolute;
    top: 3px;
    left: 0;
    width: 26.5px;
    text-indent: 8.5px;
  }
}

.leftContainer {
  width: 100%;
  @include g.flex-column-start;
  gap: var(--spacing-section-small);
  @include g.responsive(g.$LARGE) {
    width: var(--left-width);
  }
}
.rightContainer {
  @include g.flex-column-start;
  gap: var(--spacing-section-small);
  @include g.responsive(g.$LARGE) {
    width: var(--right-width,); // Use dynamic width with 70% as fallback
  }
}

@use '../../global.style.module' as g;

.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include g.responsiveSectionSpacing;
}

.content {
  width: 100%;
  text-align: left;
  @include g.typographySpacingRegular;

  @include g.responsive(g.$LARGE) {
    width: 610px;
  }

  @include g.responsive(g.$X_LARGE) {
    width: 789px;
  }

  li {
    list-style-type: none;
    position: relative;
    padding-left: 26.5px;
  }

  li + li {
    margin-top: 12px;
  }

  li::before {
    content: '\2022';
    font-size: 18px;
    line-height: 20px;
    position: absolute;
    top: 3px;
    left: 0;
    width: 26.5px;
    text-indent: 8.5px;
  }
}
